// import '/src/css/tailwind.pcss';
import '/src/css/app.pcss';
import './cookieconsent.js';
import * as Sentry from "@sentry/browser";


import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Glide from '@glidejs/glide';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

Sentry.init({
  dsn: "https://968c78577f03d19c0d1c5082cb5ac64d@o4507894694674432.ingest.us.sentry.io/4507894716956672",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/learnsphere\.ca/, /^https:\/\/learnsphere\.ddev\.site/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

window.addEventListener("error", function (e) {
  var eleArray = ["IMG", "SCRIPT", "LINK"];
  var resourceMap = {
      "IMG": "Picture file",
      "SCRIPT": "JavaScript file",
      "LINK": "CSS file"
  };
  var ele = e.target;
  if(eleArray.indexOf(ele.tagName) !== -1){
      var url = ele.tagName === "LINK" ? ele.href : ele.src;

      // Ignore errors with extension:// protocol
      if (url.startsWith("extension://")) {
          return true; // Prevent reporting or logging this error
      }

      console.log("src：" + url + " File " + resourceMap[ele.tagName] + " failed loading.");
      Sentry.captureException(new Error("src：" + url + " File " + resourceMap[ele.tagName] + " failed loading."));
      return true; // Don't show in console
  }
}, true);


function addMultipleEventListener(element, events, handler) {
  events.forEach(e => element.addEventListener(e, handler));
}

window.addEventListener('load', () => {
  console.log("Bootstrapping LearnSphere");

  addMultipleEventListener(document, ['keyup'], (event) => {
    if (event.key === 'Escape') {
      menu?.setAttribute('aria-expanded', 'false'); 
      wrap?.classList.remove('menu-open');
      tl.reverse()
    }
  });

});

const toggleCSSclasses = (el, ...cls) => cls.map(cl => el.classList.toggle(cl));

// Collect re-used DOM references
const navbar = document.querySelector('#navbar');
const pageContainer = document.querySelector('.page-container');
const body = document.querySelector('body');

// Initialize menu flyout
const subnavs = document.querySelectorAll('#primary-menu .subnav');
subnavs.forEach((subnav) => {
  subnav.classList.remove('large:hidden');
  subnav.classList.add('large:flex');
});
const mainNavItems = document.querySelectorAll('#primary-menu > ul > li.has-children');
var navopen = false;
mainNavItems.forEach((item) => {
  addMultipleEventListener(item, ['mouseenter', 'focusin'], () => {
    navopen = true;
    navbar?.classList.add('nav-open');
  });
  addMultipleEventListener(item, ['mouseleave', 'focusout'], () => {
    navopen = false;
    window.setTimeout(() => {
      if (!navopen)
        navbar?.classList.remove('nav-open');
    }, 250);
  });
});

// Configure smooth scrolling
const scrollLinks = document.querySelectorAll('[data-smooth-scroll],a[href^="#"]');
scrollLinks.forEach((link) => {
  link.addEventListener('click', (event) => {
    const linkURL = link.getAttribute('href');
    const targetID = linkURL.substring(linkURL.lastIndexOf('#'));
    if (targetID && targetID !== '#') {
      event.preventDefault();
      gsap.to(window, {duration: 1, scrollTo:targetID});
    }
  });
});

// Initialize sticky menu
ScrollTrigger.matchMedia({
  '(min-width: 1366px)': function() {
    // Configure nav reveal
    const navHeight = navbar.offsetHeight;
    const triggerHeight = document.querySelector('body').offsetHeight;
    var reversedAt = 0;
    ScrollTrigger.create({
      trigger: 'body',
      start: 'top+=' + navHeight + 'px top',
      end: 'bottom',
      onUpdate: function({progress, direction, isActive}) {
        if (isActive) {
          navbar.classList.add('standby');
          if (direction < 0) {
            if (reversedAt - (progress * triggerHeight) > 100) {
              navbar?.classList.add('reveal');
              navbar?.classList.add('animation-enabled');
            }
          } else {
            reversedAt = progress * triggerHeight;
            navbar?.classList.remove('reveal');
          }
        } else {
          navbar?.classList.remove('standby');
          navbar?.classList.remove('reveal');
          navbar?.classList.remove('nav-open');
          navbar?.classList.remove('animation-enabled');
        }
      }
    });
  },
});

// Initialize search toggle
const searchToggle = document.getElementById('search-toggle');
if (searchToggle) {
  searchToggle.addEventListener('click', () => {
    const targetID = searchToggle.getAttribute('aria-controls');
    const target = document.querySelector(targetID);
    const lastStatus = searchToggle.getAttribute('aria-expanded');
    searchToggle.setAttribute('aria-expanded', lastStatus == 'false' ? 'true' : 'false');
    target?.classList.toggle('collapsed');
    navbar?.classList.toggle('search');
    if (!target.classList.contains('collapsed')) {
      const searchField = document.getElementById('search');
      searchField?.focus();
    }
  });
}

// Initialize mobile menu toggle
const menuToggleButton = document.querySelector('#menu-toggle');
menuToggleButton?.addEventListener('click', () => {
  menuToggleButton.classList.toggle('is-active');
  navbar?.classList.toggle('mobile-nav-active');
  pageContainer?.classList.toggle('mobile-nav-active');
  if (navbar?.classList.contains('mobile-nav-active')) {
    const height = navbar.offsetHeight;
    body.style.maxHeight = height + 'px';
  } else {
    body.style.maxHeight = 'none';
  }
});

const lazyImages = [].slice.call(document.querySelectorAll(".lazy-loaded-image.lazy"));
let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
  entries.forEach(function(entry) {
      if (entry.isIntersecting) {
        let lazySource = entry.target.querySelector('source');
        let lazyImage = entry.target.querySelector('img'); 
        if (lazySource) {
          lazySource.srcset = lazySource.dataset.srcset;
        }
        if (lazyImage) {
          lazyImage.srcset = lazyImage.dataset.srcset;
        } else {
          entry.target.srcset = entry.target.dataset.srcset;
        }
        entry.target.classList.remove("lazy");
        lazyImageObserver.unobserve(entry.target);
      }
  });
});
lazyImages?.forEach(function(lazyImage) {
    lazyImageObserver.observe(lazyImage);
});

// Initialize fancy ordered list block
const fancyordereditems =  document.querySelectorAll('.fancyol');
fancyordereditems.forEach((item) => {
  const orderLabel = item.querySelector('.index');
  const contentContainer = item.querySelector('.content');
  const contentWidth = contentContainer.offsetWidth;
  gsap.to(orderLabel, {
    scrollTrigger: {
      trigger: item,
      start: 'top bottom-=200px',
      end: '+=500',
      scrub: 1
    },
    x: -(contentWidth * 0.75),
    ease: 'none'
  });
});

// Initialize carousels
const carousels = document.querySelectorAll('.glide');
if (carousels.length) {
  carousels.forEach((carousel) => {
    new Glide(carousel, {
      type: 'carousel',
      focusAt: 'center',
      perView: 5,
      gap: 16,
      breakpoints: {
        1024: {
          perView: 3
        },
        500: {
          perView: 1
        }
      }
    }).mount();
  });
}

const expandToggles = document.querySelectorAll('.expand-toggle');
expandToggles.forEach((button) => {
  addMultipleEventListener(button, ['click'], (event) => {
    const button = event.target;
    const controls = button.getAttribute('aria-controls');
    const expanded = button.getAttribute('aria-expanded');
    const toggle = button.dataset.toggle;
    const target = document.querySelector(controls);
    target.classList.toggle(toggle);
    button.setAttribute('aria-expanded', expanded == 'false');

  })
});

// Initialize number counter
const counters = document.querySelectorAll('.counter');
gsap.registerEffect({
  name:"counter",
  extendTimeline:true,
  defaults:{
    end:0,
    duration:2,
    ease:"power1",
    increment:1,
  },
  effect: (targets, config) => {
    let tl = gsap.timeline()
    let num = targets[0].innerText.replace(/\,/g,'')
    targets[0].innerText = num
    tl.to(targets, {
      duration:config.duration, 
      innerText:config.end, 
      //snap:{innerText:config.increment},
      modifiers:{
        innerText:function(innerText){
          return  gsap.utils.snap(config.increment, innerText).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      ease:config.ease}, 0)
    
      return tl
    }
});

let ctl = gsap.timeline();
counters.forEach((counter) => {
  const number = counter.querySelector('.counter__number');
  
  ctl.from(counter, {opacity: 0});
  ctl.counter(number, {
    end: number.dataset.end,
    duration: (number.dataset.duration.length > 0) ? number.dataset.duration : 2,
    ease: "linear"
  }, "-=1");

  // ScrollTrigger for each counter
  ScrollTrigger.create({
    trigger: counter, // the element to watch
    start: "top 50%", // trigger when the top of the counter reaches the middle of the viewport
    end: "bottom 50%", // animation ends when the bottom reaches the middle
    animation: ctl, // attach the timeline to ScrollTrigger
    toggleActions: "play none none none", // play the animation when it reaches the start point
    markers: false 
  });
});;